

  .swiper {
    width: 100%;
    /* height: 600px; */
    margin-left: auto;
    margin-right: auto;
    /* aspect-ratio: 16 / 3; */
    height: 60vw;
    max-height: 800px;
    background-color: #fff;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* width: 100px; */
    /* height: 300px; */
  
    /* Center slide text vertically */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 30vw !important;
    /* aspect-ratio: 16 / 3; */
    max-height: 400px;
    /* object-fit: cover; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* background-size: contain; */
  }
  .card-info {
    transition: opacity 0.5s ease-in-out;
    opacity: 0; /* Start with the element invisible */
}

/* When the parent element is hovered, fade in the card-info */
div:hover .card-info {
    opacity: 1;
}
  